import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Drawer, Input, Form } from 'antd'
import * as actions from '../actions'

const UpdateCompany = ({ pageNo, size, record, updateCompany, fetchCompaniesList }) => {
  const [isVisible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const [crmType, setCrmType] = useState(
    window.localStorage.getItem('crm') && JSON.parse(window.localStorage.getItem('crm')).value
      ? JSON.parse(window.localStorage.getItem('crm')).value
      : 'response',
  )

  const onFinishHandler = async values => {
    await updateCompany({
      campaignId: values.id,
      name: values.name,
      guid: values.guid,
      user_id: values.user_id,
      user_password: values.user_password,
      paayKey: values.paayKey,
      rebatesKey: values.rebatesKey,
      apiURL: values.apiURL,
      chargeDelay: values.chargeDelay,
      delayAttempt: values.delayAttempt,
    })
    await fetchCompaniesList({ page: pageNo, size: size, crmType: crmType })
    setVisible(false)
    form.resetFields()
  }

  return (
    <>
      <i
        className='zmdi zmdi-edit zmdi-hc-2x zmdi-hc-lg txt-warning users-list-action_item'
        title='Edit'
        type='primary'
        onClick={() => {
          setVisible(true)
        }}
      />
      <Drawer
        title='Update Site'
        className={'add_product_popup'}
        onClose={() => setVisible(false)}
        visible={isVisible}
        forceRender
      >
        <Form layout='vertical' hideRequiredMark onFinish={onFinishHandler} form={form}>
          <Form.Item
            initialValue={record.name}
            name='name'
            label='Name'
            rules={[{ required: true, message: 'Please input site name!' }]}
          >
            <Input />
          </Form.Item>

          {crmType === 'response' || crmType === 'velobill' ? (
            <Form.Item
              initialValue={record.guid}
              name='guid'
              label='Guid'
              rules={[{ required: true, message: 'Please input company guid!' }]}
            >
              <Input />
            </Form.Item>
          ) : null}
          {crmType === 'sublytics' || crmType === 'sticky' ? (
            <div>
              <Form.Item
                initialValue={record.user_id}
                name='user_id'
                label='CRM User Id'
                rules={[{ required: true, message: 'Please input company user_id!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                initialValue={record.user_password}
                name='user_password'
                label='CRM User Password'
                rules={[{ required: true, message: 'Please input company user_password!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                initialValue={record.apiURL}
                name='apiURL'
                label='CRM apiURL'
                rules={[{ required: true, message: 'Please input company apiURL!' }]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          {crmType === 'konnektive' || crmType === 'checkoutchamp' || crmType === 'nmi' || crmType === 'rocketgate' ? (
            <div>
              <Form.Item
                initialValue={record.user_id}
                name='user_id'
                label='CRM API User'
                rules={[{ required: true, message: 'Please input company user_id!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                initialValue={record.user_password}
                name='user_password'
                label='CRM API User Password'
                rules={[{ required: true, message: 'Please input company user_password!' }]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}
          {crmType === 'pockyt' ? (
            <div>
              <Form.Item
                name='apiURL'
                initialValue={record.apiURL}
                label='CRM apiURL'
                rules={[{ required: true, message: 'Please input company apiURL!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='descriptor'
                label='Descriptor'
                initialValue={record.descriptor}
                rules={[{ required: true, message: 'Please input descriptor!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='guid'
                label='Token'
                initialValue={record.guid}
                rules={[{ required: true, message: 'Please input Token!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='user_id'
                initialValue={record.user_id}
                label='Merchant No'
                rules={[{ required: true, message: 'Please input company merchantNo!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                initialValue={record.user_password}
                name='user_password'
                label='Store No'
                rules={[{ required: true, message: 'Please input company storeNo!' }]}
              >
                <Input />
              </Form.Item>
            </div>
          ) : null}

          <Form.Item initialValue={record.paayKey} name='paayKey' label='Paay Key' rules={[{ max: 200 }]}>
            <Input />
          </Form.Item>
          <Form.Item initialValue={record.rebatesKey} name='rebatesKey' label='Rebates Key'>
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={record.chargeDelay}
            name='chargeDelay'
            label='Delay in Hours for Charge'
            rules={[{ max: 200 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            initialValue={record.delayAttempt}
            name='delayAttempt'
            label='Delay in Hours for Next Attempt'
            rules={[{ max: 200 }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            initialValue={record.id}
            name='id'
            label='id'
            rules={[{ required: true, message: 'Please input site name!' }]}
          >
            <Input disabled />
          </Form.Item>
          <div
            style={{
              position: 'absolute',
              left: 0,
              display: 'flex',
              justifyContent: 'space-between',
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => setVisible(false)}>Close</Button>
            <Button type='primary' htmlType='submit' className='add-new-site_button'>
              Update
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  )
}

const mapDispatchToProps = {
  fetchCompaniesList: actions.fetchCompaniesList,
  updateCompany: actions.updateCompany,
}

export default connect(null, mapDispatchToProps)(UpdateCompany)
